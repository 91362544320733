<template>
	<div>
		<div class="header-box">
			<img src="../../assets/icon/logo.jpg" alt="" class="logo" />
		</div>
		<div class="banner">
			<img src="../../assets/icon/banner.png" alt="" class="logo" />
		</div>
		<div class="search-box">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane name="first">
					<span slot="label">
						<div class="tabsText">
							<div>Vehicle Code</div>
							<div>車台番号検索</div>
						</div>
					</span>
					<div class="search-input">
						<div class="">
							<div class="elinputext">車台番号「ZNE10-1234567」の場合</div>
							<div class="elinputs">
								<div class="el_input inputs">
									<el-input v-model="searchVal0" placeholder="ZNE10" @blur="blur" @focus="focus($event, 'Model Designation Number', 'searchVal0')" @input="input"></el-input>
									<div class="inputsdiv">—</div>
									<el-input class="inputsdivs" v-model="searchVal1" placeholder="1234567" @blur="blur" @focus="focus($event, 'Classification number', 'searchVal1')" @input="input1"></el-input>
								</div>
								<historyComp
									width="305"
									top="80"
									v-if="isShowHistory && focusParams.type === 'Model Designation Number' && focusParams.data.length"
									:list="focusParams.data"
									@change="searchHistoryChange"
									@delete="searchHistoryDelete"
								/>
								<historyComp
									width="305"
									left="345"
									top="80"
									v-if="isShowHistory && focusParams.type === 'Classification number' && focusParams.data.length"
									:list="focusParams.data"
									@change="searchHistoryChange"
									@delete="searchHistoryDelete"
								/>
								<div class="search-btn" @click="searchResult"><i class="el-icon-search"></i></div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane name="second">
					<span slot="label">
						<div class="tabsText">
							<div>Vehicle Search</div>
							<div>車両検索</div>
						</div>
					</span>
					<div class="filter">
						<div class="name">
							初度登録年月 ※不明の場合は未入力でも検索できます
							<br />
							「Brand」「Model」を選択後入力可能です
						</div>
						<div class="input">
							<el-input :disabled="!formInline.Brand || !formInline.Model" v-model="filter['SOP-EOP']" placeholder="初度登録年月(例:201001)" @blur="blur($event, 'SOP-EOP')"></el-input>
						</div>
					</div>
					<el-form :inline="true" :model="formInline" class="vehicle-form-inline" label-width="200px" ref="validateForm">
						<el-form-item prop="Brand">
							<template slot="label">
								<div class="label-box">
									Brand
									<span class="count" v-if="brandList.count">{{ brandList.count }}</span>
								</div>
							</template>
							<el-select v-model="formInline.Brand" placeholder="brand" @change="selectModel">
								<el-option :label="item" :value="item" v-for="(item, index) in brandList.items" :key="index"></el-option>
							</el-select>
							<div class="error-tip" v-show="isBrand">please select Brand</div>
						</el-form-item>
						<el-form-item prop="Model">
							<template slot="label">
								<div class="label-box">
									Model
									<span class="count" v-if="modelList.count">{{ modelList.count }}</span>
								</div>
							</template>
							<el-select v-model="formInline.Model" placeholder="Model" filterable @change="selectChassis($event, 'Model')">
								<el-option :label="item" :value="item" v-for="(item, index) in modelList.items" :key="index"></el-option>
							</el-select>
							<div class="error-tip" v-show="isModel">please select Model</div>
						</el-form-item>
						<el-form-item>
							<template slot="label">
								<div class="label-box">
									Chassis Code
									<span class="count" v-if="ChassisList.count">{{ ChassisList.count }}</span>
								</div>
							</template>
							<el-select v-model="formInline.Chassis" placeholder="Chassis Code" filterable @change="selectChassis($event, 'Chassis_Code', 0)">
								<el-option :label="item" :value="item" v-for="(item, index) in ChassisList.items" :key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<template slot="label">
								<div class="label-box">
									Transmission Type
									<span class="count" v-if="transmissionList.count">{{ transmissionList.count }}</span>
								</div>
							</template>
							<el-select v-model="formInline.Transmission" placeholder="Transmission Type" filterable @change="selectChassis($event, 'Transmission_Type', 1)">
								<el-option :label="item" :value="item" v-for="(item, index) in transmissionList.items" :key="index"></el-option>
							</el-select>
						</el-form-item>
						<!-- <el-form-item>
							<template slot="label">
								<div class="label-box">
									Year
									<span class="count" v-if="yearList.count">{{ yearList.count }}</span>
								</div>
							</template>
							<el-select v-model="formInline.Year" placeholder="Year" filterable @change="selectChassis($event, 'Year', 2)">
								<el-option :label="item" :value="item" v-for="(item, index) in yearList.items" :key="index"></el-option>
							</el-select>
						</el-form-item> -->

						<el-form-item>
							<template slot="label">
								<div class="label-box">
									SOP-EOP
									<span class="count" v-if="sopEopList.count">{{ sopEopList.count }}</span>
								</div>
							</template>
							<el-select v-model="formInline['SOP-EOP']" placeholder="SOP-EOP" filterable @change="selectChassis($event, 'SOP-EOP', 2)">
								<el-option :label="item" :value="item" v-for="(item, index) in sopEopList.items" :key="index"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item>
							<template slot="label">
								<div class="label-box">
									Grade
									<span class="count" v-if="gradeList.count">{{ gradeList.count }}</span>
								</div>
							</template>
							<el-select v-model="formInline.Grade" placeholder="Grade" filterable @change="selectChassis($event, 'Grade', 3)">
								<el-option :label="item" :value="item" v-for="(item, index) in gradeList.items" :key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<template slot="label">
								<div class="label-box">
									Drivers Position
									<span class="count" v-if="driversList.count">{{ driversList.count }}</span>
								</div>
							</template>
							<el-select v-model="formInline.Drivers" placeholder="Drivers Position" filterable @change="selectChassis($event, 'Drivers_Position', 4)">
								<el-option :label="item" :value="item" v-for="(item, index) in driversList.items" :key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="Engine Model">
							<template slot="label">
								<div class="label-box">
									Engine Model
									<span class="count" v-if="engineList.count">{{ engineList.count }}</span>
								</div>
							</template>
							<el-select v-model="formInline.Engine" placeholder="Engine Model" filterable @change="selectChassis($event, 'Engine_Model', 5)">
								<el-option :label="item" :value="item" v-for="(item, index) in engineList.items" :key="index"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<template slot="label">
								<div class="label-box">
									Displacement
									<span class="count" v-if="displacementList.count">{{ displacementList.count }}</span>
								</div>
							</template>
							<el-select v-model="formInline.Displacement" placeholder="Displacement" filterable @change="selectChassis($event, 'Displacement', 6)">
								<el-option :label="item" :value="item" v-for="(item, index) in displacementList.items" :key="index"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
					<div @click="onSubmit" class="Vehicle-Search">Vehicle Search</div>
				</el-tab-pane>
				<el-tab-pane name="third">
					<div class="filter">
						<div class="name">初度登録年月 ※不明の場合は未入力でも検索できます</div>
						<div class="input">
							<el-input v-model="filter['Chassis Code']" placeholder="初度登録年月(例:201001)" @blur="blur($event, 'Chassis_Code')"></el-input>
						</div>
					</div>
					<span slot="label">
						<div class="tabsText" style="width: 180px">
							<div>Chassis Code / Model-Claf NO.</div>
							<div>フル型式検索/型式指定-類別区分番号検索</div>
						</div>
					</span>
					<div class="search-input">
						<div class="">
							<div class="elinputext">
								「フル型式」か「型式指定-類別区分番号」のいずれかの入力で検索できます
								<br />
								※「フル型式」の場合は前方3桁以上の入力でも検索できます。
							</div>

							<div class="flex" style="position: relative">
								<div class="el_input">
									<el-input v-model="modelNum" placeholder="ACA38W-BPXGK　or　16133-0077" @input="toUpCase" @blur="blur" @focus="focus($event, 'ACA38W-BPXGK　or　16133-0077', 'modelNum')"></el-input>
								</div>
								<historyComp
									v-if="isShowHistory && focusParams.type === 'ACA38W-BPXGK　or　16133-0077' && focusParams.data.length"
									:list="focusParams.data"
									@change="searchHistoryChange"
									@delete="searchHistoryDelete"
								/>
								<div class="search-btn" @click="searchChassis"><i class="el-icon-search"></i></div>
							</div>
						</div>
					</div>
					<div class="errorTip" v-if="isTip">Please enter at least three letters</div>
				</el-tab-pane>
				<el-tab-pane name="fourth">
					<span slot="label">
						<div class="tabsText">
							<div>OEM Search(JAPAN)</div>
							<div>OEM検索（日本）</div>
						</div>
					</span>
					<div class="search-input">
						<el-input v-model="oemCode" placeholder="OEM Code " @focus="focus($event, 'OEM Search Japan', 'oemCode')" @input="inputOem"></el-input>
						<historyComp
							v-if="isShowHistory && focusParams.type === 'OEM Search Japan' && focusParams.data.length"
							:list="focusParams.data"
							@change="searchHistoryChange"
							@delete="searchHistoryDelete"
						/>
						<div class="search-btn" @click="searchOem"><i class="el-icon-search"></i></div>
					</div>
				</el-tab-pane>
				<el-tab-pane name="six">
					<span slot="label">
						<div class="tabsText">
							<div>OEM Search(CHINA)</div>
							<div>OEM検索（中国）</div>
						</div>
					</span>
					<div class="search-input">
						<el-input v-model="oemCodeChina" placeholder="OEM Code" @focus="focus($event, 'OEM Search China', 'oemCodeChina')" @input="inputOemChina"></el-input>
						<historyComp
							v-if="isShowHistory && focusParams.type === 'OEM Search China' && focusParams.data.length"
							:list="focusParams.data"
							@change="searchHistoryChange"
							@delete="searchHistoryDelete"
						/>
						<div class="search-btn" @click="searchOemChina"><i class="el-icon-search"></i></div>
					</div>
				</el-tab-pane>
				<el-tab-pane name="five">
					<span slot="label">
						<div class="tabsText">
							<div>OES Search</div>
							<div>OES検索</div>
						</div>
					</span>
					<div class="oes-box">
						<div class="search-input">
							<el-select v-model="brand" placeholder="brand">
								<!-- <el-option :label="item" :value="item" v-for='(item,index) in brandList.items' :key='index'></el-option> -->
							</el-select>
							<el-input v-model="oesCode" placeholder="OEM Code"></el-input>
							<div class="search-btn" @click="searchResult"><i class="el-icon-search"></i></div>
						</div>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="brand flex-center">
			<img style="width: 1400px" src="../../assets/icon/brand.png" alt="" />
		</div>
	</div>
</template>

<script>
import historyComp from "@/components/search/history";
import { searchHistoryMixins } from "./index";
import { getCarFields } from "@/service/api/index.js";
export default {
	name: "home",
	components: { historyComp },
	mixins: [searchHistoryMixins],
	data() {
		return {
			activeName: "first",
			isBrand: false,
			isModel: false,
			formInline: {},
			isTip: false,
			brandList: [],
			modelList: [],
			ChassisList: [],
			transmissionList: [],
			gradeList: [],
			yearList: [],
			driversList: [],
			engineList: [],
			displacementList: [],
			sopEopList: [],
			_sopEopList: [],
			// labelList: ["Chassis Code", "Transmission Type", "Year", "Grade", "Drivers Position", "Engine Model", "Displacement"],
			labelList: ["Chassis_Code", "Transmission_Type", "SOP-EOP", "Grade", "Drivers_Position", "Engine_Model", "Displacement"],

			searchVal: "",

			searchVal0: "",
			searchVal1: "",

			modelNum: "",
			oemCode: "",
			oemCodeChina: "",
			oesCode: "",
			brand: "",
			formInlines: {
				Brand: "",
				Model: "",
				Chassis: "",
				Transmission: "",
				Year: "",
				Grade: "",
				Drivers: "",
				Engine: "",
				Displacement: "",
				"SOP-EOP": "",
			},

			filter: {
				"SOP-EOP": "",
				Chassis_Code: "",
			},
		};
	},

	created() {
		this.getCarFields();
	},
	mounted() {
		// 绑定监听事件
		window.addEventListener("keydown", this.keyDown);
	},
	destroyed() {
		// 销毁事件
		window.removeEventListener("keydown", this.keyDown, false);
	},
	methods: {
		keyDown(e) {
			// 回车则执行登录方法 enter键的ASCII是13
			// console.log(e, "keyDown", this.activeName);
			if (e.keyCode === 13) {
				if (this.activeName == "first") {
					this.searchResult();
				} else if (this.activeName == "third") {
					this.searchChassis();
				}
				// this.login(); // 定义的登录方法
			}
		},

		input(e) {
			this.searchVal0 = e.toUpperCase();
		},
		input1(e) {
			this.searchVal1 = e.toUpperCase();
		},
		inputOem(e) {
			this.oemCode = e.toUpperCase();
		},
		inputOemChina(e) {
			this.oemCodeChina = e.toUpperCase();
		},
		// 是否为6位数字
		// isSixDigitNumber(str) {
		// 	return /^\d{6}$/.test(str);
		// },
		// 过滤输入框
		blur(event, key) {
			if (key == "SOP-EOP") {
				if (this.filter[key]) {
					const val = this.filter[key];
					const reg = this.$tools.isSixDigitNumber(val);

					if (reg) {
						const { items } = this.sopEopList;
						console.log(this.sopEopList);
						let filter = [];
						for (let index = 0; index < items.length; index++) {
							const element = items[index].split("-");
							console.log("element", element);
							const min = Number(element[0]);
							const max = !element[1] ? 999999 : Number(element[1]);
							console.log("max", max, min);
							if (val <= max && val >= min) filter.push(items[index]);
						}
						this.sopEopList.count = filter.length;
						this.sopEopList.items = filter;
					}
				} else {
					this._sopEopList = this._sopEopList ?? [];
					console.log("this._sopEopList", this._sopEopList);
					if (this._sopEopList.length === this.sopEopList.length) return;
					this.sopEopList = { ...this._sopEopList };
				}

				if (this.sopEopList.items.length == 1) this.formInline["SOP-EOP"] = this.sopEopList.items[0];
				else this.formInline["SOP-EOP"] = "";

				this.labelList.forEach((item, index) => {
					this.getChassis(item, this.formInline["SOP-EOP"], "SOP-EOP", index);
				});
			}
		},
		async getCarFields() {
			let prams = {
				field: "brand",
			};
			const { datas } = await getCarFields(prams);
			this.brandList = datas;
		},

		selectModel(val) {
			this.formInline = {
				Brand: val,
				Model: "",
				Chassis: "",
				Transmission: "",
				Year: "",
				Grade: "",
				Drivers: "",
				Engine: "",
				Displacement: "",
			};

			this.isBrand = false;
			this.reset();
			this.getModel(val);
		},

		toUpCase(val) {
			this.modelNum = val.toUpperCase();
		},
		reset() {
			this.ChassisList.count = 0;
			this.transmissionList.count = 0;
			this.yearList.count = 0;
			this.gradeList.count = 0;
			this.driversList.count = 0;
			this.engineList.count = 0;
			this.displacementList.count = 0;
		},
		selectChassis(val, name, index) {
			if (name == "Model") {
				this.isModel = false;
				this.formInline = {
					Brand: this.formInline.Brand,
					Model: val,
					Chassis: "",
					Transmission: "",
					Year: "",
					Grade: "",
					Drivers: "",
					Engine: "",
					Displacement: "",
					"SOP-EOP": "",
				};
				this.formInlines = {
					Brand: this.formInline.Brand,
					Model: val,
					Chassis: "",
					Transmission: "",
					Year: "",
					Grade: "",
					Drivers: "",
					Engine: "",
					Displacement: "",
					"SOP-EOP": "",
				};
			}
			this.labelList.forEach((item, index) => {
				this.getChassis(item, val, name, index);
			});
		},
		async getModel(val) {
			let prams = {
				field: "model",
				brand: val,
			};
			const { datas } = await getCarFields(prams);
			this.modelList = datas;
		},
		dealObjectValue(obj) {
			var param = {};
			if (obj === null || obj === undefined || obj === "") return param;
			for (var key in obj) {
				if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
					param[key] = obj[key];
				}
			}
			return param;
		},

		async getChassis(item, val, name, index) {
			let prams = {};

			if (name == "Chassis_Code") {
				this.formInlines.Chassis = val;

				console.log(name, "name", this.focusParams.Chassis);
			} else if (name == "Transmission_Type") {
				this.formInlines.Transmission = val;
			} else if (name == "Year") {
				this.formInlines.Year = val;
			} else if (name == "SOP-EOP") {
				this.formInlines["SOP-EOP"] = val;
			} else if (name == "Grade") {
				this.formInlines.Grade = val;
			} else if (name == "Drivers_Position") {
				this.formInlines.Drivers = val;
			} else if (name == "Engine_Model") {
				this.formInlines.Engine = val;
			} else if (name == "Displacement") {
				this.formInlines.Displacement = val;
			}
			let itemes = item;
			if (item != "SOP-EOP") {
				itemes = item.toLowerCase();
			}
			prams = {
				field: itemes,
				brand: this.formInlines.Brand,
				model: this.formInlines.Model,
				chassis_code: this.formInlines.Chassis,
				transmission_type: this.formInlines.Transmission,
				"SOP-EOP": this.formInlines["SOP-EOP"],
				grade: this.formInlines.Grade,
				drivers_position: this.formInlines.Drivers,
				engine_model: this.formInlines.Engine,
				displacement: this.formInlines.Displacement,
			};
			const { datas } = await getCarFields(this.dealObjectValue(prams));
			// console.log(index,'index')
			// console.log(item, "prams", val, name, prams, index,this.focusParams.Chassis);
			if (item == "Chassis_Code" && name != item) {
				console.log(name, "name", this.formInlines.Chassis);
				this.ChassisList = datas;
				if (this.ChassisList.items.length == 1) {
					this.formInline.Chassis = this.ChassisList.items[0];
				} else {
					this.formInline.Chassis = "";
				}
				// this.getChassis("Transmission Type", val, name, index + 1);
			} else if (item == "Transmission_Type" && name != item) {
				this.transmissionList = datas;
				if (this.transmissionList.items.length == 1) {
					this.formInline.Transmission = this.transmissionList.items[0];
				} else {
					this.formInline.Transmission = "";
				}
				// this.getChassis("Year", val, name, index + 1);
			} else if (item == "Year" && name != item) {
				this.yearList = datas;
				if (this.yearList.items.length == 1) {
					this.formInline.Year = this.yearList.items[0];
				} else {
					this.formInline.Year = "";
				}

				// this.getChassis("Grade", val, name, index + 1);
			} else if (item == "SOP-EOP" && name != item) {
				this.sopEopList = datas;
				this._sopEopList = { ...datas };
				if (this.sopEopList.items.length == 1) {
					this.formInline["SOP-EOP"] = this.sopEopList.items[0];
				} else {
					this.formInline["SOP-EOP"] = "";
				}

				// this.getChassis("Grade", val, name, index + 1);
			} else if (item == "Grade" && name != item) {
				this.gradeList = datas;
				if (this.gradeList.items.length == 1) {
					this.formInline.Grade = this.gradeList.items[0];
				} else {
					this.formInline.Grade = "";
				}
				// this.getChassis("Drivers Position", val, name, index + 1);
			} else if (item == "Drivers_Position" && name != item) {
				this.driversList = datas;
				if (this.driversList.items.length == 1) {
					this.formInline.Drivers = this.driversList.items[0];
				} else {
					this.formInline.Drivers = "";
				}
				// this.getChassis("Engine Model", val, name, index + 1);
			} else if (item == "Engine_Model" && name != item) {
				// console.log(this.focusParams.Engine,'this.focusParams.Engine')
				this.engineList = datas;
				if (this.engineList.items.length == 1) {
					this.formInline.Engine = this.engineList.items[0];
				} else {
					this.formInline.Engine = "";
				}
				// this.getChassis("Displacement", val, name, index + 1);
			} else if (item == "Displacement" && name != item) {
				this.displacementList = datas;
				if (this.displacementList.items.length == 1) {
					this.formInline.Displacement = this.displacementList.items[0];
				} else {
					this.formInline.Displacement = "";
				}
			}
		},
		async searchResult() {
			this.searchVal = this.searchVal0 + "-" + this.searchVal1;
			if (this.searchVal) {
				// console.log('this.searchVal0',this.searchVal0,'this.searchVal1',this.searchVal1);

				if (this.searchVal0) {
					this.setSearchHistoryData(this.searchVal0, "searchVal0", "Model Designation Number");
				}
				if (this.searchVal1) {
					this.setSearchHistoryData(this.searchVal1, "searchVal1", "Classification number");
				}

				this.$router.push({
					path: "/vehicleResult",
					query: {
						val: this.searchVal,
						type: 1,
					},
				});
			}
		},
		searchOem() {
			let index = 0;
			if (this.oemCode.length < 7) return this.$message.error("Enter at least 7 digits of OE Code");
			if (this.oemCode) {
				this.setSearchHistoryData();
				this.$router.push({
					path: "/searchResultTable",
					query: {
						val: this.oemCode,
						index: index,
					},
				});
			}
		},
		searchOemChina() {
			let index = 1;
			// if (this.oemCodeChina.length < 7) return this.$message.error("Enter at least 7 digits of OE Code");

			if (this.oemCodeChina) {
				this.setSearchHistoryData();
				this.$router.push({
					path: "/searchResultTable",
					query: {
						val: this.oemCodeChina,
						index: index,
					},
				});
			}
		},
		onSubmit() {
			if (!this.formInline.Brand) {
				this.isBrand = true;
				return false;
			}
			if (!this.formInline.Model) {
				this.isModel = true;
				return false;
			}
			let obj = {
				brand: this.formInline.Brand,
				model: this.formInline.Model,
				chassis_code: this.formInline.Chassis,
				transmission_type: this.formInline.Transmission,
				Year: this.formInline.Year,
				grade: this.formInline.Grade,
				drivers_position: this.formInline.Drivers,
				engine_model: this.formInline.Engine,
				displacement: this.formInline.Displacement,
				"SOP-EOP": this.formInline["SOP-EOP"],
			};
			localStorage.setItem("vehicleData", JSON.stringify(obj));
			this.$router.push({
				path: "/vehicleResult",
				query: {
					type: 2,
					filter: this.filter["SOP-EOP"],
				},
			});
		},
		searchChassis() {
			let len = this.modelNum.length;
			if (len < 3) {
				this.isTip = true;
			} else {
				this.isTip = false;
				this.setSearchHistoryData();

				this.$router.push({
					path: "/vehicleResult",
					query: {
						type: 3,
						val: this.modelNum,
						filter: this.filter["Chassis Code"],
					},
				});
			}
		},
	},
};
</script>
<style lang="less" scoped>
.filter {
	margin-top: 50px;
	div:nth-child(2) {
		width: 200px;
		margin-top: 10px;
	}
}
.header-box {
	height: 65px;
	margin-left: 53px;
	display: flex;
	align-items: center;
	img {
		width: 320px;
		height: 50px;
	}
}
.error-tip {
	color: #f56c6c;
	font-size: 12px;
	height: 22px;
	line-height: 22px;
}
.banner {
	img {
		// width: 1400px;
		width: 100%;
	}
}
/deep/ .el-tabs__content {
	overflow: visible !important;
}
.search-box {
	width: 1400px;
	margin: 80px auto 0 auto;

	/deep/ .el-tabs__nav-wrap {
		border-bottom: 2px solid #e2e2e2;
	}
	/deep/ .el-tabs__item {
		border-bottom: none !important;
	}
	/deep/ .el-tabs__active-bar {
		background-color: transparent !important;
	}
	/deep/ .el-tabs__item.is-active {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: #fd7e14;
		background: #fffaf5;
		border-bottom: 2px solid #fd7e14 !important;
	}
	/deep/ .el-tabs__item {
		width: 200px;
		// padding:0 26px;
		height: 131px;
		line-height: 25px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 18px;
		color: #000000;
		white-space: pre-line;
		display: flex;
		box-sizing: border-box;
		justify-content: center;
		align-items: center;
		border-bottom: 2px solid #e2e2e2;
	}
	/deep/ .el-tabs__nav {
		display: flex;
	}
	/deep/ .el-tabs__nav-wrap::after {
		height: 0;
	}
}
.tabsText {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	text-align: center;
	padding: 10rpx 0;
}
.search-input {
	width: 800px;
	// margin: 0 auto;
	margin-top: 65px;
	display: flex;
	justify-content: flex-start;
	// align-items: center;
	position: relative;
	/deep/ .el-input {
		width: 650px;
	}
	.inputs {
		display: flex;
		align-items: center;
		/deep/ .el-input {
			width: 305px;
		}
		// ::v-deep .el-input__inner {

		//   // text-transform: uppercase !important;
		//   // border-radius: 0;
		// }
		.inputsdivs {
			::v-deep .el-input__inner {
				// text-transform: uppercase !important;
				border-radius: 0;
			}
		}
		.inputsdiv {
			width: 40px;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #fd7e14;
			color: #fff;
		}
	}
	.el_input {
		// ::v-deep .el-input__inner {
		//   text-transform: uppercase !important;
		// }
	}

	/deep/ .search-btn {
		cursor: pointer;
		background-color: #fd7e14;
		border: none;
		border-radius: 0px 4px 4px 0px;
		width: 150px;
		color: #fff;
		height: 60px;
		line-height: 60px;
		text-align: center;
		box-sizing: border-box;
	}
	/deep/ .el-input__inner {
		border-radius: 4px 0px 0px 4px;
		border-right: 0;
		height: 60px;
	}
}
.brand {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 118px;
	padding-bottom: 40px;
}
.vehicle-form-inline {
	// margin-top: 65px;
	margin-top: 20px;
	.el-form-item {
		width: 24%;
	}
	/deep/ .el-form-item__label {
		display: block;
	}
	/deep/ .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
	/deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
		float: left;
	}
}
.Vehicle-Search {
	cursor: pointer;
	width: 300px;
	height: 55px;
	background: #fd8d2f;
	border-radius: 4px;
	margin: 0 auto;
	margin-top: 28px;
	text-align: center;
	line-height: 55px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 500;
	font-size: 16px;
	color: #ffffff;
}
.oes-box {
	.search-input {
		width: 830px;
	}

	.el-select {
		width: 250px;
		margin-right: 20px;
		/deep/ .el-input {
			width: 230px;
		}
		/deep/ .el-input__inner {
			width: 230px;
			border-right: 1px solid #dcdfe6;
		}
	}
	.el-input {
		width: 400px;
	}
}
.count {
	margin-left: 10px;
	display: inline-block;
	width: 39px;
	height: 25px;
	line-height: 25px;
	text-align: center;
	background: #f7f7f7;
	border-radius: 14px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 500;
	font-size: 12px;
	color: #a0a0a0;
}
.label-box {
	text-align: left;
}
.errorTip {
	width: 800px;
	margin: 0 auto;
	margin-top: 6px;
	color: #f56c6c;
	font-size: 12px;
}
.elinputs {
	display: flex;
	align-items: center;
}
.elinputext {
	margin-top: -30px;
	margin-bottom: 30px;
}
</style>
