<template>
	<div class="search-result-box">
		<navbar />
		<breadcrumb :list="breadcrumbList" />
		<div class="result-box">
			<vehicleInfo :resultData="resultData" v-if="isShot" />
			<searchResult :isShow="false" />
			<div v-if="vehicleList.length == 0" class="empty-data">No data found</div>
			<div v-else>
				<colorBox :resultData="resultData" v-if="isShot" />
				<vehicleBox :list="vehicleList" v-if="isShot" />
				<vehicleList :vehicleList="vehicleList" v-if="!isShot" @selectVehicle="selectVehicle" />
			</div>
			<div class="specific-box" v-if="vehicleList.length == 1 || specific">
				<div class="specific-title">
					<img src="@/assets/icon/reset.png" alt="" />
					<span>One-shot search for specific items</span>
				</div>
				<el-tabs v-model="activeName">
					<el-tab-pane label="Engine & Transmission" name="second">
						<div class="spec-box">
							<div class="spec-item transition" v-for="item in tabsData.second" :key="item.name" @click="itemClick(item)">
								<img :src="item.img" alt="" />
								<div>{{ item.name }}</div>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="Chassis & Suspension" name="first">
						<div class="spec-box">
							<div class="spec-item transition" v-for="item in tabsData.first" :key="item.name" @click="itemClick(item)">
								<img :src="item.img" alt="" />
								<div>{{ item.name }}</div>
							</div>
						</div>
					</el-tab-pane>

					<el-tab-pane label="Exterior & Interior" name="third">
						<div class="spec-box">
							<div class="spec-item transition" v-for="item in tabsData.third" :key="item.name" @click="itemClick(item)">
								<img :src="item.img" alt="" />
								<div>{{ item.name }}</div>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
import { commonMixins } from "@/mixins/common.js";
import vehicleBox from "@/components/vehicleBox/index";
import vehicleList from "@/components/vehicleList/index";
import specificList from "../../utils/specificList";
import { getChassisNo, getHwvin, getSearchCar, getMixCar } from "@/service/api/index.js";
export default {
	name: "vehicleResult",
	components: { vehicleList, vehicleBox },
	mixins: [commonMixins],

	data() {
		return {
			activeName: "second",
			specificList: specificList,
			resultData: null,
			isShot: true,
			vehicleList: [],
			breadcrumbList: [],
			tabsData: {
				first: [
					{
						name: "Disc brake pad",
						img: require("@/assets/icon/imgs/Disc brake pad.png"),
					},
					{
						name: "Disc roter",
						img: require("@/assets/icon/imgs/Disc roter.jpg"),
					},
					{
						name: "Brake shoe",
						img: require("@/assets/icon/imgs/Brake shoe.jpg"),
					},
					{
						name: "Brake master cylinder",
						img: require("@/assets/icon/imgs/Brake master cylinder.jpg"),
					},
					{
						name: "Drive shaft boots",
						img: require("@/assets/icon/imgs/Drive shaft boots.jpg"),
					},

					{
						name: "Hub bearing",
						img: require("@/assets/icon/imgs/Hub bearing.jpg"),
					},
					{
						name: "Rear muffler",
						img: require("@/assets/icon/imgs/Rear muffler.jpg"),
					},
					{
						name: "Shock absorber",
						img: require("@/assets/icon/imgs/Shock absorber.png"),
					},
					{
						name: "Stabilizer link",
						img: require("@/assets/icon/imgs/Stabilizer link.png"),
					},
					{
						name: "Drive shaft assembly",
						img: require("@/assets/icon/imgs/Drive shaft assembly.jpg"),
					},
					{
						name: "Rack and pinion",
						img: require("@/assets/icon/imgs/Rack and pinion.jpg"),
					},
				],
				second: [
					{
						name: "Oil Filter",
						img: require("@/assets/icon/imgs/Oil Filter.jpg"),
					},
					{
						name: "Air cleaner",
						img: require("@/assets/icon/imgs/Air cleaner.jpg"),
					},
					{
						name: "Water pump",
						img: require("@/assets/icon/imgs/Water pump.jpg"),
					},
					{
						name: "Clutch cover",
						img: require("@/assets/icon/imgs/Clutch cover.jpg"),
					},
					{
						name: "Clutch disc",
						img: require("@/assets/icon/imgs/Clutch disc.jpg"),
					},
					{
						name: "Belts",
						img: require("@/assets/icon/imgs/Belts.jpg"),
					},
					{
						name: "Battery",
						img: require("@/assets/icon/imgs/Battery.jpg"),
					},
					{
						name: "Spark / glow plug",
						img: require("@/assets/icon/imgs/Spark.jpg"),
					},
					{
						name: "Oxygen sensor",
						img: require("@/assets/icon/imgs/Oxygen sensor.jpg"),
					},
					{
						name: "Engine assembly",
						img: require("@/assets/icon/imgs/Engine assembly.jpg"),
					},
					{
						name: "Radiator",
						img: require("@/assets/icon/imgs/Radiator.jpg"),
					},
					{
						name: "Transmission assembly",
						img: require("@/assets/icon/imgs/Transmission assembly.jpg"),
					},
					{
						name: "Alternator",
						img: require("@/assets/icon/imgs/Alternator.jpg"),
					},
					{
						name: "Starter motor",
						img: require("@/assets/icon/imgs/Starter motor.jpg"),
					},
					{
						name: "Cooler compressor",
						img: require("@/assets/icon/imgs/Cooler compressor.jpg"),
					},
					{
						name: "Torque converter",
						img: require("@/assets/icon/imgs/Torque converter.jpg"),
					},
				],
				third: [
					{
						name: "Cabin filter",
						img: require("@/assets/icon/imgs/Cabin filter.jpg"),
					},
					{
						name: "Wiper",
						img: require("@/assets/icon/imgs/Wiper.jpg"),
					},
					{
						name: "Front door",
						img: require("@/assets/icon/imgs/Fornt door.jpg"),
					},
					{
						name: "Rear door",
						img: require("@/assets/icon/imgs/Rear door.jpg"),
					},
					{
						name: "Head lamp",
						img: require("@/assets/icon/imgs/Head lamp.jpg"),
					},
					{
						name: "Front bumper",
						img: require("@/assets/icon/imgs/Front bumper.jpg"),
					},
					{
						name: "Rear bumper",
						img: require("@/assets/icon/imgs/Rear bumper.jpg"),
					},
					{
						name: "Tail lamp",
						img: require("@/assets/icon/imgs/Tail lamp.jpg"),
					},
					{
						name: "Windshield",
						img: require("@/assets/icon/imgs/Windshield.jpg"),
					},
					{
						name: "Rear glass",
						img: require("@/assets/icon/imgs/Rear glass.png"),
					},
					{
						name: "Side mirror",
						img: require("@/assets/icon/imgs/Side mirror.jpg"),
					},
				],
			},
			params: {},
			specific: false,
		};
	},
	created() {
		let vin = this.$route.query;
		vin.type = Number(vin.type);
		this.query = vin;
		this.query.chassis_no = this.$route.query.val;

		if (vin.type == 2) {
			this.isShot = false;
			const vehicleData = JSON.parse(localStorage.getItem("vehicleData"));

			this.getSearchCar(vehicleData);
			const bread = (vehicleData.Brand || vehicleData.brand) + "&" + (vehicleData.Model || vehicleData.model);

			this.breadcrumbList = [
				{
					name: bread,
					path: "",
				},
			];
		} else if (vin.type == 1) {
			// this.breadcrumbList = [vin.val];
			this.breadcrumbList = [
				{
					name: vin.val,
					path: "",
				},
			];
			this.params.chassis_no = vin.val;
			this.getResult(vin);
			this.getVehicle();
		} else if (vin.type == 3) {
			this.isShot = false;
			// this.breadcrumbList = [vin.val];
			this.breadcrumbList = [
				{
					name: vin.val,
					path: "-",
				},
			];
			this.getMixCar(vin.val);
		}
	},
	methods: {
		filterVehicleList(data, bool = true) {
			const val = this.query.filter;
			const reg = this.$tools.isSixDigitNumber(val);
			let filter = data;

			if (reg && bool) {
				filter = [];

				for (let index = 0; index < data.length; index++) {
					const { SOP, EOP } = data[index];
					let min = Number(SOP);
					let max = EOP === "-" ? 999999 : Number(EOP);
					if (val <= max && val >= min) filter.push(data[index]);
				}
			}

			return filter;
		},
		async getMixCar(val) {
			let prams = {
				search_data: val,
			};
			const { datas } = await getMixCar(prams);
			console.log("getMixCar", datas);
			this.vehicleList = this.filterVehicleList(datas);
		},
		selectVehicle(index) {
			this.vehicleList = [this.vehicleList[index]];
			this.$tools.scrollTo();
			sessionStorage.setItem("vehicleList", JSON.stringify(this.vehicleList));
			// console.log("this.vehicleList", this.vehicleList);
		},
		async getSearchCar(vehicleData) {
			const { datas } = await getSearchCar(vehicleData);
			console.log("datas", datas);

			this.vehicleList = this.filterVehicleList(datas.car_list, vehicleData["SOP-EOP"]);
		},
		async itemClick(item) {
			this.query.standardnames = item.name;

			if (this.resultData) {
				this.query.hw_id = this.resultData.veh_id;
				this.query.chassis_no = this.resultData.chassis_no;
				sessionStorage.setItem("vehicleList", JSON.stringify(this.resultData));
			} else {
				const { chassiscode, hw_id, ChassisCode } = this.vehicleList[0];

				// this.query.chassis_no = chassiscode || ChassisCode;
				delete this.query.chassis_no;
				this.query.hw_id = hw_id;

				sessionStorage.setItem("vehicleList", JSON.stringify(this.vehicleList[0]));
			}

			await this.getOeSearchApi(false);

			// 若主组只有一个 那么直接跳转 mainGroupEngine
			// console.log("this.mainGroup", this.mainGroup);
			if (this.mainGroup.length === 1) {
				const { cate1_no, cate1_name, cate_min_no, cate_min_name, pos } = this.mainGroup[0];

				this.$router.push({
					path: "/mainGroupEngine",
					query: {
						...this.$route.query,
						cate1_no,
						cate_main_name: cate1_name,
						cate_min_no,
						cate_min_name,
						pos,
						bread: this.breadcrumbList[0].name,
						quick: true,
					},
				});
			} else {
				// const { brand_code } = this.resultData;
				this.$router.push({
					path: "/searchResult",
					query: {
						...this.query,
						standardnames: item.name,
						bread: this.breadcrumbList[0].name,
						quick: true,
					},
				});
			}
		},
		// 短码查询
		async getResult() {
			const { datas } = await getChassisNo(this.params);
			this.resultData = datas;
		},
		async getVehicle() {
			const { datas } = await getHwvin(this.params);

			this.vehicleList = datas.car_info;
			this.specific = true;
		},
	},
};
</script>
<style lang="less" scoped>
.search-result-box {
	background: #f8f9fb;
	width: 100%;
	padding-bottom: 30px;
	min-height: calc(100vh - 30px);
}

.bread-box {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.container {
	background: #fff;
	.bread-box {
		width: 1400px;
		margin: 0 auto;
		height: 60px;
		.el-icon-location {
			margin-right: 6px;
			font-size: 16px;
		}
		/deep/ .el-breadcrumb__item,
		/deep/ .el-breadcrumb__inner,
		/deep/ .el-breadcrumb__separator {
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: #fd7e14 !important;
		}
	}
}
.result-box {
	width: 1350px;
	margin: 0 auto;
	border: 1px solid #e6e6e6;
	background: #fff;
	margin-top: 15px;
	padding: 25px;

	.vehicle-info {
		height: 109px;
		background: #fffaf5;
		padding: 0 21px;
	}
	.vehicle-info {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.vehicle-left {
		text-align: center;
		border-right: 1px solid #e6e6e6;
		padding-right: 19px;
		height: 70px;
		img {
			margin-top: 10px;
		}
	}
	.vehicle-item {
		padding-left: 20px;
		display: flex;
		justify-content: flex-start;
		.item {
			margin-right: 40px;
			width: 266px;
		}
		.label {
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: #999999;
		}
	}
}
.search-result-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e6e6e6;
	padding-bottom: 22px;
	.search-box {
		width: 80px;
		height: 40px;
		line-height: 40px;
		background: #fd7e14;
		border-radius: 0px 100px 100px 0px;
		color: #fff;
		text-align: center;
	}
	.search-form {
		display: flex;
		justify-content: flex-start;
		margin-top: 20px;
		/deep/ .el-input__inner {
			border-radius: 100px 0px 0px 100px;
			border-right: none;
		}
	}
	.setting {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: #000000;
		i {
			font-size: 20px;
			margin-right: 10px;
		}
	}
}
.color-box {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 30px;
	.color-item {
		margin-right: 58px;
		span {
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 16px;
			color: #999999;
		}
	}
}
.specific-box {
	margin-top: 30px;
	padding-bottom: 30px;
	.specific-title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 30px;
		img {
			margin-right: 6px;
		}
	}
	/deep/ .el-tabs__active-bar {
		background-color: transparent !important;
	}
	/deep/ .el-tabs__item.is-active {
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		font-size: 18px;
		color: #fd7e14;
		background: #fffaf5;
		border-bottom: 2px solid #fd7e14;
	}
	/deep/ .el-tabs__item {
		width: 243px;
		// padding:0 26px;
		height: 52px;
		line-height: 25px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 18px;
		color: #000000;
		white-space: pre-line;
		display: flex;
		box-sizing: border-box;
		justify-content: center;
		align-items: center;
		border-bottom: 2px solid #e2e2e2;
	}
	/deep/ .el-tabs__nav {
		display: flex;
	}
	/deep/ .el-tabs__nav-wrap::after {
		height: 0;
	}
}
.spec-box {
	display: flex;
	margin-top: 30px;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 20px;
	.spec-item {
		border: 1px solid #e6e6e6;
		border-radius: 4px;
		text-align: center;
		padding-bottom: 20px;

		width: 18%;
		box-sizing: border-box;
		img {
			width: 60%;
			height: auto;
		}
	}
}
.empty-data {
	padding-top: 10px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	font-size: 16px;
	color: #999999;
}
</style>
